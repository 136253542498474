import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import GalleryPage from './pages/GalleryPage'; // Import your GalleryPage component
import BookingPage from './pages/BookingPage';
import ScrollToTop from './components/ScrollToTop'; // Import the ScrollToTop component

const App = () => (
  <Router>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/gallery/:tab" element={<GalleryPage />} /> {/* Modify this to point to GalleryPage */}
      <Route path="/booking" element={<BookingPage />} />
      {/* Add other routes as needed */}
    </Routes>
  </Router>
);

export default App;
