import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Gallery from '../components/Gallery';
import ScrollingText from '../components/ScrollingText';
import About from '../components/About';
import GoogleReviews from '../components/GoogleReviews';
import Contact from '../components/Contact';

// Custom hook to use Intersection Observer
const useInView = (options) => {
  const [inView, setInView] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setInView(entry.isIntersecting);
    }, options);

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) observer.unobserve(elementRef.current);
    };
  }, [options]);

  return [inView, elementRef];
};

const Home = () => {
  const [aboutInView, aboutRef] = useInView({ threshold: 0.1 });
  const [servicesInView, servicesRef] = useInView({ threshold: 0.1 });
  const [galleryInView, galleryRef] = useInView({ threshold: 0.1 });

  return (
    <div>
      <Header />
      <div className="overflow-x-hidden">  {/* Prevent horizontal scrolling */}
        <div id="home" className="w-full">
          <Hero />
        </div>
        <ScrollingText />

        <div id="about" className="border-t" ref={aboutRef}>
          {/* Apply transition classes based on visibility */}
          <div className={`${aboutInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'} transition-all duration-700 ease-in-out`}>
            <About />
          </div>
        </div>

        <div id="services" className="w-full" ref={servicesRef}>
          <div className={`${servicesInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'} transition-all duration-700 ease-in-out`}>
            <Services />
          </div>
        </div>

        <div id="reviews" className="w-full">
          <GoogleReviews />
        </div>

        <div id="gallery" className="w-full" ref={galleryRef}>
          <div className={`${galleryInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'} transition-all duration-700 ease-in-out`}>
            <Gallery />
          </div>
        </div>

        <div id="contact" className="border-t">
          <Contact />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Home;
