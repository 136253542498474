import React from 'react';
import { Link } from 'react-router-dom';
import suitImage from '../assets/suit.jpg'; // replace with actual image path
import bridalImage from '../assets/bridal.jpg'; // replace with actual image path
import repairImage from '../assets/service3.jpg'; // replace with actual image path
import casualImage from '../assets/casual.jpg'; // replace with actual image path
import bespokeImage from '../assets/bespoke.jpg'; // replace with actual image path

const Gallery = () => {
  return (
    <section className="py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-thin text-deep-brown font-butler mb-8">Gallery</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {/* Suits Section */}
          <div className="relative">
            <Link to="/gallery/suit">
              <img src={suitImage} alt="Suits" className="rounded-full w-40 h-40 mx-auto mb-4 object-cover" />
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2">Suits</h3>
              </div>
            </Link>
          </div>

          {/* Bridal Section */}
          <div className="relative">
            <Link to="/gallery/bridal">
              <img src={bridalImage} alt="Bridal" className="rounded-full w-40 h-40 mx-auto mb-4 object-cover" />
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2">Bridal</h3>
              </div>
            </Link>
          </div>

          {/* Repairs Section */}
          <div className="relative">
            <Link to="/gallery/repair">
              <img src={repairImage} alt="Repairs" className="rounded-full w-40 h-40 mx-auto mb-4 object-cover" />
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2">Repairs</h3>
              </div>
            </Link>
          </div>

          {/* Casual Section */}
          <div className="relative">
            <Link to="/gallery/casual">
              <img src={casualImage} alt="Casual" className="rounded-full w-40 h-40 mx-auto mb-4 object-cover" />
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2">Casual</h3>
              </div>
            </Link>
          </div>

          {/* Bespoke Garments Section */}
          <div className="relative">
            <Link to="/gallery/bespokeGarment">
              <img src={bespokeImage} alt="Bespoke Garments" className="rounded-full w-40 h-40 mx-auto mb-4 object-cover" />
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2">Bespoke Garments</h3>
              </div>
            </Link>
          </div>
        </div>
        <div className="border-b border-deep-brown border-solid mt-8"></div>
      </div>
    </section>
  );
};

export default Gallery;
